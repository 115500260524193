@use '../../scss/' as *;


/* Header
-------------------------------------------------------------- */

header {
    border-bottom: 1px solid var(--primary-color4);
    padding-right: 0 !important;

    #site-header-inner {
        @include flex(center, left);

        #site-logo {
            flex-shrink: 0;
            margin-right: 5%;
            padding: 20px 0;

            @include desktop-1200 {
                margin-right: 1%;
            }
        }

        #main-nav {
            flex-shrink: 1;
        }

        .header-right {
            margin-left: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                margin-right: 25px;

                .tf-button {
                    display: none;
                }
            }
            

            .tf-button,
            .user,
            .mode-switch {
                margin-right: 8px;
            }

            .user,
            .mode-switch {
                width: 48px;
                height: 48px;
                line-height: 46px;
                text-align: center;
                background-color: var(--primary-color6);
                border: 1px solid var(--primary-color4);
                border-radius: 50%;
                display: inline-block;
            }

            .user {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                svg {
                    path {
                        fill: var(--primary-color8);
                    }
                }
            }

            .mode-switch {
                margin-right: 0;

                &.moon {
                    display: none;
                }

                &.sun {
                    display: block;
                }
            }

            .tf-button {
                padding: 11px 35px 11px 37px;
                font-weight: 700;
                font-size: 16px;

            }
        }
    }

    &.absolute {
        // position: absolute;
        width: 100%;
        border-bottom: none;
        z-index: 100;
    }
}

.is_dark header #site-header-inner .header-right {
    .mode-switch {
        margin-right: 0;

        &.moon {
            display: block;
        }

        &.sun {
            display: none;
        }
    }
}


.dark-mode-container {
    margin-right: 30px;  
  
    @media (min-width: 1024px) {
      margin-right: 40px;  
    }
  }
  


/* Site Logo
-------------------------------------------------------------- */

/* Menu
-------------------------------------------------------------- */
/* Main Nav */

#main-nav {
    ul {
        margin: 0px;

        li {
            position: relative;
            list-style: none;
        }
    }
}

#main-nav {
    >ul {
        >li {

            &.active {
                >a {
                    color: var(--primary-color3);
                }
            }

            float: left;
            padding: 31px 30px 31px 13px;

            @include desktop-1300 {
                padding: 31px 10px;
            }

            @include desktop-1200 {
                padding: 31px 5px;
            }

            >a {
                position: relative;
                font-size: 15px;
                line-height: 26px;
                font-weight: 200;
                display: block;
                color: var(--primary-color5);
                padding-right: 15.5px;

                &:hover {
                    color: var(--primary-color3);
                }
            }
        }
    }

    >.menu {
        >li {
            &.menu-item-has-children {
                >a {
                    &::after {
                        content: '\f107';
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 300;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        @include transitionY;
                    }
                }
            }
        }
    }
}

/* Sub Menu */

#main-nav {
    .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 250px;
        background-color: var(--primary-color6);
        border: 1px solid var(--primary-color15);
        border-radius: 8px;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        transform: translateY(15px);

        .sub-menu {
            left: 105%;
            top: 0%;
            opacity: 0 !important;
            visibility: hidden;

            &::before {
                display: none;
            }
        }

        li {


            a {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--primary-color5);
                padding: 10px 15px;
            }
        }
    }

    .right-sub-menu {
        left: auto;
        right: 0;
    }

    @include tablet {
        margin: 0 auto;
        width: 40%;
        height: 1000vh;
        position: absolute;
        top: 0;
        left: -10px;
        z-index: 999999;
        background-color: var(--background-body);
        box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
        transform: translateX(-100%);
        transition: transform 0.5s ease;

        .sub-menu {
            background: transparent;
            padding: 15px 0;

            li a {
                color: var(--primary-color5);
            }
        }

        &.active {
            transform: translateX(0);

            .menu li {
                .sub-menu {
                    position: relative;
                    display: none;
                    opacity: 1;
                    visibility: visible;
                    box-shadow: none;

                    &::after {
                        display: none;
                    }
                }

                &.active {
                    .sub-menu {
                        display: block;
                        transform: translateY(0);
                        width: 100%;
                        padding-bottom: 0;
                    }
                }
            }
        }

        .menu {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0;

            li {
                padding: 15px 0;
                border-bottom: 1px solid rgb(255 255 255 / 10%);
                width: 100%;

                a {
                    padding: 0 15px;
                }

                ul li {
                    border-top: 1px solid rgb(255 255 255 / 10%);

                    padding: 10px 20px !important;

                    &:last-child {
                        border: none;

                    }
                }

                &.menu-item-has-children>a::after {
                    content: '\f107';
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 300;
                    position: absolute;
                    right: 20px;
                    top: 40%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &.menu-item-has-children.active {
                    padding-bottom: 5px;
                }
            }
        }
    }

    @include mobile {
        width: 60%;
    }
}


#main-nav>ul>.current-menu-item .current-menu-item>a,
#main-nav>ul>.current-menu-item>a {
    color: var(--primary-color3);
}

#main-nav li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

#main-nav li .sub-menu li:hover .sub-menu {
    opacity: 1 !important;
    visibility: visible;
}



#main-nav .sub-menu li.current-item a,
#main-nav .sub-menu li a:hover {
    color: var(--primary-color3);
}

#main-nav {
    >ul {
        >li {
            .sub-menu {
                li {
                    position: relative;

                    a {

                        transition: all 0.15s linear;
                        -webkit-transition: all 0.15s linear;
                        -moz-transition: all 0.15s linear;
                        -o-transition: all 0.15s linear;

                        &:hover,
                        &.active {
                            padding-left: 35px;
                            color: var(--primary-color3);

                            &::after {
                                width: 12px;
                            }
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 2px;
                            background: var(--primary-color3);
                            left: 15px;
                            top: 50%;
                            transform: translate(0%, -50%);
                            @include transition3;
                        }
                    }

                    &.current-item {
                        a {
                            padding-left: 35px;

                            &::after {
                                width: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Mobile Menu */

#main-nav-mobi {
    display: block;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999999;
    background-color: var(--primary-color6);

    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            text-align: left;
            cursor: pointer;
            padding: 0;
            border-top: 1px solid var(--primary-color4);
            position: relative;

            &:first-child {
                border-top: 0px;
            }
        }
    }
}

#main-nav-mobi {
    ul {
        >li {
            >a {
                color: var(--primary-color5);
                display: inline-block;
                font-size: 16px;
                line-height: 48px;
                padding: 0 15px;
                font-weight: 500;
            }

            &.current-menu-item {
                >a {
                    color: var(--primary-color3);
                }
            }
        }
    }

    .sub-menu {
        .menu-item {
            a {
                margin-left: 15px;
            }
        }
    }
}

#main-nav-mobi ul li>ul>li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
}

#main-nav-mobi .current-item>a,
#main-nav-mobi ul>li>a:hover {
    color: var(--primary-color3);
}

#main-nav-mobi {
    .menu-item-has-children {
        .arrow {
            cursor: pointer;
            display: inline-block;
            font-size: 20px;
            font-family: "FontAwesome";
            line-height: 48px;
            position: absolute;
            right: 15px;
            text-align: center;
            top: 0;
            width: 48px;

            &::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f107";
                color: var(--primary-color2);
            }
        }
    }
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
    color: var(--primary-color3);
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
    background: var(--primary-color3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#main-nav-mobi .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    @include transition3;
}

#main-nav-mobi .menu-item-has-children .arrow.active:before {
    content: "\f107";
}

#main-nav-mobi ul ul li {
    background-color: var(--primary-color6);
    border-top: 1px solid var(--primary-color4);
}


/* Mobile Menu Button */
.mobile-button {
    @include transition3;
    display: none;
    position: relative;
    width: 26px;
    height: 26px;
    top: 10px;
    right: 0;

    margin-left: 10px;
    background-color: transparent;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    &:before,
    &:after,
    span {
        background-color: var(--primary-color5);
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 3px;
        width: 100%;
        left: 0;
        top: 50%;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    span {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 50%;
        overflow: hidden;
        text-indent: 200%;
    }

    &:before {
        -webkit-transform: translate3d(0, -7px, 0);
        -moz-transform: translate3d(0, -7px, 0);
        transform: translate3d(0, -7px, 0);
    }

    &:after {
        -webkit-transform: translate3d(0, 7px, 0);
        -moz-transform: translate3d(0, 7px, 0);
        transform: translate3d(0, 7px, 0);
    }

    &.active span {
        opacity: 0;
    }

    &.active:before {
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        -moz-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
    }

    &.active:after {
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        -moz-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
    }

    @include tablet {
        display: block;

    }

    @include mobile {
        position: absolute;
        top: 46px;
        right: 5x;
    }
}

.tf-button-submit {
    &:hover {
        opacity: .9;
        color: #fff;
    }
}

.is_dark {
    .tf-button-submit {
        &:hover {
            color: #fff;
        }
    }
}

/* Header Fixed */
@keyframes slideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.header {

    background: var(--background-body);
    transition: all 1s ease;


    &.is-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
        z-index: 9999;
        background: var(--background-body);
        border: none;
        opacity: 1;
        // -webkit-transition: all 0.5s ease;
        // -moz-transition: all 0.5s ease;
        // -ms-transition: all 0.5s ease;
        // -o-transition: all 0.5s ease;
        // transition: all 1s ease;
        animation: slideIn 0.5s ease-in-out;
    }
}

.popup-user {
    display: inline-block;
    cursor: pointer;
    margin-right: 8px;
    position: relative;

    .avatar {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .avatar_popup {
        position: absolute;
        z-index: 100;
        right: 0;
        padding: 31px 20px;
        top: 100%;
        margin-top: 20px;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid var(--primary-color15);
        background: var(--primary-color6);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-1rem);
        transform: translateY(-1rem);
        font-size: 16px;
        width: 311px;

        &.visible {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        h6 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 15px;
            color: var(--primary-color5);
        }

        .title {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 5px;
            color: var(--primary-color5);
        }

        .price {
            margin-bottom: 10px;

            .style {
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: #5142FC;
            }
        }

        .code {
            @include flex(flex-start, space-between);
            padding-bottom: 14px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--primary-color4);

            p {
                font-size: 14px;
                line-height: 22px;
            }

            svg {
                path {
                    fill: var(--product-color5);
                }
            }
        }

        .links {
            li {
                padding: 8px 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }

            a {
                @include flex(center, left);

                svg {
                    margin-right: 13px;

                    path {
                        fill: var(--primary-color14);
                    }
                }

                span {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
    }
}

.logo-light {
    display: block;
}

.logo-dark {
    display: none;
}

.is_dark {
    .logo-light {
        display: none;
    }

    .logo-dark {
        display: block;
    }
}