.product-img{
    height:517px;
    width:682px;
}

.content span{
    color: #fff !important;
}
.content strong{
    color: #fff !important;
}

.carousel-indicators button {
    display: none;
}


@media only screen and (max-width: 767px) {
    .product-img{
        height:517px;
        width:682px;
    }
  }

  @media only screen and (max-width: 500px) {
    .product-img{
        height:317px;
        width:400px;
    }
  }
  .tf-explore-more {
  padding: 0 0 77px;
}

.tf-explore-more .tf-heading {
  padding-bottom: 40px;
}

.tf-explore-more.faq {
  padding-bottom: 50px;
}