@use '../../scss/' as *;

.launch-section {
  padding-left: 7%;
  padding-right: 7%;
}

.launch-button {
  border: 2px solid white;
  background-color: transparent;
  color: black;
  cursor: pointer;
  padding: 12px 63px;
  background: transparent;
  border-radius: 12px;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 700;
  transition: all 0.3s ease;
}

.launch-button:hover {
  background-color: #3749E9;
}

.card {
  border: 2px solid;
  border-bottom: 4px solid #4286f4 !important;
  border-image: linear-gradient(to right, #4286f4, #55c3ff);
  border-image-slice: 1;
  background-color: transparent;
  padding: 20px;
  margin: 20px auto;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.card-title {
  background: -webkit-linear-gradient(left, #4286f4, #55c3ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.card-paragraph {
  color: #fff;
}

.point-icon {
  height: 20px;
  margin-right: 5px;
}

.points-list {
  text-align: left;
}

// Case Studies 

.key-value-list {
  list-style: none;
  padding: 0;
}

.key-value-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.key {
  flex: 2;
  text-align: left;
}

.dots {
  flex-grow: 2;
  white-space: nowrap; // Prevent dots from wrapping to a new line
}

.value {
  text-align: right;
}

// Not Ready

.card-not-ready {
  background: linear-gradient(100deg, rgba(37, 49, 70, 0.4) 0%, rgba(27, 35, 50, 0.4) 100%);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 1px, rgba(255, 255, 255, 0.1) 0px 1px 0px inset;
  border: none;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  margin: 20px auto;
  text-align: center;
}

.card-title-not-ready {
  color: rgb(255, 244, 181);
  background-color: rgb(255, 244, 181);
  background-image: linear-gradient(135deg, rgb(255, 207, 181) 0%, rgb(229, 255, 181) 100%);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-paragraph-not-ready {
  color: rgb(148, 167, 198);
}

.button-section {
  text-align: center;
}

// Support

.card-support {
  display: flex;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-column {
  flex: 1;
  padding-top: 20px;

  // background-color: #f2f2f2; /* Background color for the icon column */
}

.icon-column-inner {
  padding: 8px;
  background-color: rgba(130, 180, 255, 0.15);
  color: rgb(53, 134, 255);
  border-radius: 4px;
}

.content-column {
  flex: 6;
  padding: 20px;
}

.icon-support {
  width: 40px;
  /* Adjust the width of the icon */
  height: 40px;
  /* Adjust the height of the icon */
}

.card-title-support {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 165%;
  text-shadow: none;
  white-space: normal;
  // font-size: 15px;
  // color: rgb(255, 255, 255);
  color: var(--primary-color5) !important;
  text-align: inherit;
  font-weight: 600;
}
.set-primary-color {
  color: var(--primary-color5) !important; 
}

.card-description-support {
  line-height: 165%;
  text-shadow: none;
  white-space: normal;
  font-size: 15px;
  color: rgb(148, 167, 198);
  text-align: inherit;
}

.button-background-blue {
  background-color: #3749E9 !important;
}

.button-background-blue:hover {
  background-color: rgb(148, 167, 198) !important;
}