/* Pagination container */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
}

/* Pagination item (page number) */
.page-item {
  margin: 0 5px;
}

/* Pagination link (page number button) */
.page-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  border: 1px solid #3498db;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for pagination links */
.page-link:hover {
  background-color: #2980b9;
}

/* Active page */
.page-item.active .page-link {
  background-color: #2980b9;
  border-color: #2980b9;
}

/* Disabled page */
.page-item.disabled .page-link {
  background-color: #ccc;
  color: #666;
  border-color: #ccc;
  cursor: not-allowed;
}


.tf-blog {
  padding: 28px 0 80px 0;
}

.tf-blog .tf-blog-item {
  box-sizing: border-box;
  padding: 24px 24px 32px 24px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  border: 1px solid #E5E5E5;
}

.tf-blog .tf-blog-item .image {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 28px;
}

.tf-blog .tf-blog-item .image img {
  width: 100%;
}

.tf-blog .tf-blog-item .title {
  margin-bottom: 20px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: left;
  justify-content: space-between;
}

.tf-blog .tf-blog-item .title .tf-button {
  padding: 8px 9px;
  border-radius: 20px;
  background: #3749E9;
  color: #fff;
}

.tf-blog .tf-blog-item .content {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px;
}

.tf-blog .tf-blog-item .meta>span {
  margin-right: 26px;
  font-size: 18px;
  line-height: 26px;
}

.tf-blog .tf-blog-item .meta>span i {
  margin-right: 7px;
  color: var(--primary-color3);
}

.tf-blog .tf-blog-item .meta .admin i {
  font-size: 15px;
  margin-left: 2px;
}

.tf-blog .tf-blog-item.is_dark {
  background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.1) 0.07%, rgba(100, 97, 113, 0.037) 99.07%);
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
}

.tf-blog .tf-blog-item.style-2 {
  padding: 24px 24px 30px 24px;
}

.tf-blog .tf-blog-item.style-2 .title {
  margin-bottom: 18px;
}

.tf-blog .tf-blog-item.style-2 .content {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

.tf-blog .load-more {
  text-align: center;
  margin-top: 10px;
}

.tf-blog .load-more .tf-button {
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 23px;
  background-color: var(--primary-color24);
  border: 1px solid var(--primary-color25);
  color: var(--primary-color14);
  font-weight: 700;
}

.tf-blog .load-more .tf-button:hover {
  background-color: #3749E9;
  border-color: #3749E9;
  color: #fff;
}

.tf-blog .load-more.style-2 {
  display: block;
  margin-top: 40px;
}

.tf-blog .main-content {
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .tf-blog .main-content {
    margin-right: 0;
  }
}

.tf-blog .side-bar .widget {
  margin-bottom: 30px;
  padding: 24px;
  background-color: var(--primary-color6);
  border-radius: 16px;
  border: 1px solid var(--primary-color15);
}

.tf-blog .side-bar .widget .widget-title {
  padding: 16px;
  background-color: var(--primary-color13);
  color: var(--primary-color14);
  margin-bottom: 30px;
  border-radius: 12px;
}

.tf-blog .side-bar .widget:last-child {
  margin-bottom: 0;
}

.tf-blog .side-bar .widget.widget-category li {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid var(--primary-color17);
}

.tf-blog .side-bar .widget.widget-category li span {
  margin-left: auto;
  color: var(--primary-color16);
  font-size: 18px;
  line-height: 26px;
}

.tf-blog .side-bar .widget.widget-category li a {
  font-size: 18px;
  line-height: 26px;
  color: var(--primary-color16);
}

.tf-blog .side-bar .widget.widget-category li a:hover {
  color: var(--primary-color3);
}

.tf-blog .side-bar .widget.widget-category li:first-child {
  padding-top: 0;
}

.tf-blog .side-bar .widget.widget-category li:last-child {
  padding-bottom: 0;
  border: none;
}

.tf-blog .side-bar .widget.widget-recent-post li {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding-bottom: 19px;
  padding-top: 19px;
  border-bottom: 1px solid var(--primary-color17);
}

.tf-blog .side-bar .widget.widget-recent-post li:first-child {
  padding-top: 0;
}

.tf-blog .side-bar .widget.widget-recent-post li:last-child {
  padding-bottom: 0;
  border: none;
}

.tf-blog .side-bar .widget.widget-recent-post li .post-img {
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
}

.tf-blog .side-bar .widget.widget-recent-post li .post-img img {
  border-radius: 8px;
}

.tf-blog .side-bar .widget.widget-recent-post li .post-content .title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 8px;
}

.tf-blog .side-bar .widget.widget-recent-post li .post-content .title a {
  color: var(--primary-color16);
}

.tf-blog .side-bar .widget.widget-recent-post li .post-content .title a:hover {
  color: var(--primary-color3);
}

.tf-blog .side-bar .widget.widget-recent-post li .post-content .post-category {
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color8);
}

.tf-blog .side-bar .widget.widget-tag .widget-title {
  margin-bottom: 28px;
}

.tf-blog .side-bar .widget.widget-tag ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin-right: -8px;
}

.tf-blog .side-bar .widget.widget-tag ul li {
  margin-right: 8px;
  margin-bottom: 11px;
}

.tf-blog .side-bar .widget.widget-tag ul li a {
  padding: 0 11px;
  border-radius: 20px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color7);
  border: 1px solid var(--primary-color4);
  line-height: 35px;
  font-weight: 700;
}

.tf-blog .side-bar .widget.widget-tag ul li a:hover {
  background-color: var(--primary-color3);
  color: #fff;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(2) a {
  padding: 0 20px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(3) a {
  padding: 0 20px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(4) a {
  padding: 0 9px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(5) a {
  padding: 0 19px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(6) a {
  padding: 0 10px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(7) a {
  padding: 0 20px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(8) a {
  padding: 0 20px;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(9) {
  margin-right: 0;
}

.tf-blog .side-bar .widget.widget-tag ul li:nth-child(9) a {
  padding: 0 14px;
}

.is_dark .tf-blog-item {
  background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.1) 0.07%, rgba(100, 97, 113, 0.037) 99.07%);
  border: 1px solid transparent;
}

.tf-blog-detail {
  padding: 28px 0 102px 0;
}